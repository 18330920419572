/* Reset some default styles for consistency */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Apply a background color to the entire page */
body {
  background-color: #fff;
  font-family: Arial, sans-serif;
}

/* Style the main content */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0px;
}

/* Style the header area */
header {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 20px;
  border-radius: 5px;
}

/* Style the button */
.custom-button {
  background-color: #4E73DF;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;

}

.custom-button:hover {
  background-color: #2952CC;
}

/* Style the input field */
input[type="text"] {
  width: 100%;


  border: none;
  background: #fff;


}

/* Style the table */
table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 5px;
}

table th {
  padding: 8px;
  text-align: left;
  background-color: #f2f2f2;
  border-bottom: 1px solid #ddd;
}

table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}
