/* GeneralSettings.css */

/* Styles for buttons */
.Button {
    padding: 8px 3%;
    min-width: 50px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
  }
  
  /* Modern styles for Save Changes button */
  .Button--Save {
    background-color: #008cba;
    color: white;
    border: none;
    font-size: small;
  }
  
  /* Modern styles for Exit button */
  .Button--Exit {
    background-color: #f44336;
    color: white;
    border: none;
    font-size: small;
  }
  